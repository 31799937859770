import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import FitnessFestContent from '../components/Events/FitnessFestContent';

export const FitnessFestPageTemplate = ({ title, blurb, videoInfo }) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <Header title={title} />
      <FitnessFestContent blurb={blurb} videoInfo={videoInfo} />
    </div>
  );
};

const FitnessFestPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FitnessFestPageTemplate
        title={frontmatter.title}
        blurb={frontmatter.blurb}
        videoInfo={frontmatter.videoInfo}
      />
    </Layout>
  );
};

export default FitnessFestPage;

export const FitnessFestPageQuery = graphql`
  query FitnessFestPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "fitness-fest-page" } }) {
      frontmatter {
        title
        blurb
        videoInfo {
          caption
          url
        }
      }
    }
  }
`;
